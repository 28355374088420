import React, { useContext, useState, useEffect, useRef } from 'react'
import openSocket from 'socket.io-client'
import { Button } from '@material-ui/core'
import { apiCall } from '../../common/api'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import SecondaryButton from '../../components/atoms/SecondaryButton'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import BiddingCard from '../../components/molecules/Bidding/BiddingCard'
import './ProductView.css'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useHistory } from 'react-router-dom'
import { handleRedirectInternal, currencyFormat, dateFormatFront } from '../../common/components'
import { bidSocketHandler, messageHandler } from '../Common/socketHandler'
import FavoriteCheckbox from '../../components/atoms/FavoriteCheckbox'
import CurrencyConverter from './CurrencyConverter'
import Popup from '../../components/organisms/Popup'
import { PhotoSwipeGallery } from 'react-photoswipe'
import 'react-photoswipe/lib/photoswipe.css'

// context
import CommonContext from '../../context/common/commonContext'
import AuthContext from '../../context/auth/authContext'
import AlertContext from '../../context/alert/alertContext'
import ReadMore from '../../components/atoms/ReadMore'
import BidHistory from '../../components/organisms/BidHistory'

function TabPanel(props) {
    const { children, value, index, html, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {html ? (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: html,
                            }}
                        ></span>
                    ) : (
                        <span>{children}</span>
                    )}
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}))

// includes both live / blind auction
const SingleAuction = ({
    product,
    images,
    downloadFile,
    setProduct,
    getProductView,
    hideBidding,
}) => {
    const authContext = useContext(AuthContext)
    const classes = useStyles()
    const alertContext = useContext(AlertContext)
    const { phrase } = useContext(CommonContext)
    const { setAlert } = alertContext
    const [value, setValue] = React.useState(0)
    const { user, responseStatus, clearResponse, saveSearch } = authContext
    const [productDescPopup, setProductDescPopup] = useState(false)
    const [isOpen, setOpen] = useState(false)

    let history = useHistory()
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const userRef = useRef(user)
    const productRef = useRef(product)
    useEffect(() => {
        userRef.current = user
        productRef.current = product
    })
    const socketHandler = (data, type) => {
        bidSocketHandler(
            data,
            type,
            userRef.current,
            productRef.current,
            phrase,
            setProduct,
            setAlert,
        )
    }

    const cancelBidHandler = (data) => {
        if (data.project_id == productRef.current.id) {
            getProductView(productRef.current.id)
        }
    }

    const productDescClose = () => {
        setProductDescPopup(false)
    }

    let options = {}

    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`)
        socket.on('realclosedupdates', (data) => {
            console.log('[PRODUCT CLOSED SOCKET] ', data)
            socketHandler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            socketHandler(data, 'bidAddtime')
        })
        socket.on('drop_price', (data) => {
            socketHandler(data, 'drop_price')
        })
        // Bid Cancellation Event => Reload the product info
        socket.on('cancelbidemitted', function (data) {
            cancelBidHandler(data)
        })
        socket.on('reservepriceupdate', function (data) {
            socketHandler(data, 'reservepriceupdate')
        })
        socket.on('bid_extended', function (data) {
            socketHandler(data, 'bid_extended')
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                socketHandler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                socketHandler(data, 'bidAddtime')
            })
            socket.off('drop_price', (data) => {
                socketHandler(data, 'drop_price')
            })
            socket.off('cancelbidemitted', function (data) {
                cancelBidHandler(data)
            })
            socket.off('reservepriceupdate', function (data) {
                socketHandler(data, 'reservepriceupdate')
            })
            socket.off('bid_extended', function (data) {
                socketHandler(data, 'bid_extended')
            })
            socket.disconnect()
        }
    }, [])

    let categories = ''
    if (product) {
        for (let i in product.multiplecategoryshow) {
            // last index
            if (i == product.multiplecategoryshow.length - 1) {
                categories += phrase[product.multiplecategoryshow[i]]
            } else {
                categories += phrase[product.multiplecategoryshow[i]] + ', '
            }
        }
    }
    const redirectFile = (event) => {
        if (event.target.alt !== 'img') {
            window.location.href = `${global.site_url}/uploads/product/${event.target.title}`
        } else {
            event.preventDefault()
            setOpen(true)
        }
    }

    return (
        <div className="auctionView container-fluid mt-2">
            <div className="customContainer">
                <div className="d-flex justify-content-start">
                    <Button className="backBtn my-2" onClick={() => history.goBack()}>
                        <span className="material-icons">arrow_back</span>Back
                    </Button>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-12 ">
                        {user.isBuyer && product.isclosed >= 0 ? (
                            <FavoriteCheckbox
                                watchlisted={product.isWatchlisted}
                                project_id={product.id}
                            />
                        ) : null}

                        <ImageGallery
                            items={images}
                            thumbnailPosition="bottom"
                            showNav={false}
                            showBullets={false}
                            showFullscreenButton={false}
                            showPlayButton={false}
                            autoPlay={true}
                            onClick={redirectFile}
                        />
                        <PhotoSwipeGallery
                            items={images}
                            isOpen={isOpen}
                            onClose={() => setOpen(false)}
                            options={options}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 col-12  pvInfo">
                        <h2 className="pvTitle"> {product.title} </h2>

                        <div className="pvGrid">
                            <div className="pvGrid">
                                <label> {phrase.no_of_items} </label>
                                <span> {product.qty} </span>
                            </div>
                            <div className="pvGrid">
                                <label> {phrase.condition} </label>
                                <span>
                                    {phrase[product.condition]
                                        ? phrase[product.condition]
                                        : product.condition}
                                </span>
                            </div>
                        </div>

                        <div className="pvGrid">
                            <div className="pvGrid">
                                <label> {phrase.lot_size} </label>
                                <span>
                                    {phrase[product.lottype]} {phrase.lot}
                                </span>
                            </div>
                            <div className="pvGrid">
                                <label> {phrase.auction_type} </label>
                                <span>
                                    {product.auctiontype == 'live'
                                        ? phrase.live_auction
                                        : product.auctiontype == 'lineitem'
                                        ? phrase.line_item_auction
                                        : phrase.blind_auction}{' '}
                                </span>
                            </div>
                        </div>

                        <div className="pvGrid">
                            <div className="pvGrid">
                                <label> {phrase.location} </label>
                                <span> {product.city} </span>
                            </div>
                            <div className="pvGrid">
                                <label>UPC/SKU</label>
                                <span>{product.upc}</span>
                            </div>
                        </div>

                        <div className="pvGrid">
                            <div className="pvGrid">
                                <label>{phrase.manufacturer}</label>
                                <span> {product.manufacturer} </span>
                            </div>
                            <div className="pvGrid">
                                <label>Model</label>
                                <span>{product.modelnumber}</span>
                            </div>
                        </div>

                        <div className="pvGrid">
                            <div className="pvGrid">
                                <label>Size</label>
                                <span> {product.size} </span>
                            </div>
                            <div className="pvGrid">
                                <label>{phrase.finish}</label>
                                <span>{product.finish}</span>
                            </div>
                        </div>

                        <div className="catgGrid">
                            <div className="pvGrid">
                                <label> {phrase.category} </label>
                                <span> {categories} </span>
                            </div>
                        </div>

                        <div className="pvDesc sdsd">
                            <label> {phrase.description} </label>
                            <ReadMore
                                limit={50}
                                data={product.desc_proc}
                                setHTML={true}
                                buttonText={phrase.view_more}
                                readMoreFunction={setProductDescPopup}
                            />
                            {/* <p
                                dangerouslySetInnerHTML={{
                                    __html:
                                        product.desc_proc.length > 150
                                            ? product.desc_proc.substring(0, 150)
                                            : product.desc_proc,
                                }}
                            ></p>
                            {product.desc_proc.length > 150 ? (
                                <a className="font-25" onClick={() => setProductDescPopup(true)}>
                                    {phrase.view_more}
                                </a>
                            ) : null} */}
                        </div>
                    </div>

                    {/* {product.auctiontype == 'lineitem' ? null : ( */}
                    <div className="col-lg-4 col-md-12 col-12 biddingCardCnt">
                        {hideBidding ? (
                            ''
                        ) : (
                            <BiddingCard
                                product={product}
                                phrase={phrase}
                                setProduct={setProduct}
                                user={user}
                            />
                        )}
                    </div>
                    {/* )} */}

                    <div className="col-lg-8 col-12 lotDetails">
                        {product.currencyexist ? <CurrencyConverter product={product} /> : null}
                        {product.document ? (
                            <div className="pull-right">
                                <SecondaryButton onClick={downloadFile}>
                                    <span className="material-icons" style={{ marginRight: '3px' }}>
                                        cloud_download
                                    </span>{' '}
                                    {phrase.download_complete_manfiest}
                                </SecondaryButton>
                            </div>
                        ) : null}
                        <h2> {phrase.lot_details} </h2>
                        <div className="customTabs">
                            <AppBar position="static">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="Product Information"
                                >
                                    <Tab label={phrase.products_description} {...a11yProps(0)} />
                                    <Tab label={phrase.condition} {...a11yProps(1)} />
                                    <Tab label={phrase.faq} {...a11yProps(2)} />
                                    <Tab label={phrase.shipping} {...a11yProps(3)} />
                                    <Tab label={phrase.payment_note} {...a11yProps(4)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={value} index={0}>
                                <h6 className="lotCount">
                                    {phrase.showing} {product.csvResult} {phrase.of}{' '}
                                    {product.csvLength} {phrase.products}
                                </h6>
                                <table className="table mobileTable saTable table-striped">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">{phrase.brand}</th>
                                            <th scope="col">{phrase.product_name}</th>
                                            <th scope="col">{phrase.category}</th>
                                            <th scope="col">{phrase.part_number}</th>
                                            <th scope="col">{phrase.qty}</th>
                                        </tr>
                                    </thead>

                                    {product.csvData == '' ? (
                                        <tbody>
                                            <tr>
                                                <td colSpan="5" className="text-center">
                                                    {phrase.no_line_items_available}
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : (
                                        <tbody
                                            className="mtInner"
                                            dangerouslySetInnerHTML={{
                                                __html: product.csvData,
                                            }}
                                        ></tbody>
                                    )}
                                </table>
                                {product.document && product.market_status == 'open' ? (
                                    <div style={{ textAlign: 'center' }}>
                                        <PrimaryButton onClick={downloadFile} width="40%">
                                            <span
                                                className="material-icons"
                                                style={{ marginRight: '3px' }}
                                            >
                                                cloud_download
                                            </span>{' '}
                                            {phrase.download_complete_manfiest}
                                        </PrimaryButton>
                                    </div>
                                ) : null}
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                {product?.desc_condition?.replace(/<p>|<\/p>/g, '')}
                            </TabPanel>
                            <TabPanel value={value} index={2} html={product.faqContent}></TabPanel>
                            <TabPanel value={value} index={3}>
                                {product.buyership == 1
                                    ? phrase.productview_1
                                    : product.shipping_service}
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                {phrase.payment_note_details}
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="row">
                <div className="col-md-12 assuranceCnt">
                    <div className="customContainer ">
                        <div className="row">
                            <div className="col-lg-8 col-12">
                                <div className="assuranceCard d-flex justify-content-between">
                                    <span className="material-icons">verified</span>
                                    <div>
                                        <h2>{phrase.buy_with_confidence}</h2>
                                        <p>{phrase.productview_2}</p>
                                        <p>{phrase.productview_3}</p>
                                        <p>{phrase.productview_4}</p>
                                        <span className="leaf">
                                            <span className="material-icons">eco</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* Long Product Description Modal*/}
            <Popup
                open={productDescPopup}
                modaltitle={phrase.description}
                handleClose={productDescClose}
                footer={<SecondaryButton label={phrase.close} onClick={productDescClose} />}
            >
                <div
                    style={{ textAlign: 'left' }}
                    dangerouslySetInnerHTML={{
                        __html: product.desc_proc,
                    }}
                ></div>
            </Popup>
            <BidHistory user={userRef.current} type={product.auctiontype} />
        </div>
    )
}

export default SingleAuction
