import React, { useState, useContext, useEffect } from 'react'
import { Button } from '@material-ui/core'
import { Link, NavLink } from 'react-router-dom'
import './Footer.css'
import AutopltContext from '../../../context/autopilot/autopltContext'
import CommonContext from '../../../context/common/commonContext'
import AuthContext from '../../../context/auth/authContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { mapData } from '../../../common/components'
import { logo, siteName } from '../../../Utils'
import { apiCall } from '../../../common/api'

const Footer = () => {
    let { getLangPhrase, language, phrase } = useContext(CommonContext)
    const autopltContext = useContext(AutopltContext)

    const { addCustomer, responseStatus: responseStatusAutoPlt } = autopltContext
    const { isAuthenticated } = useContext(AuthContext)

    const validationArray = Yup.object({
        email: Yup.string().email('Invalid email format'),
    })

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            let subsribeFormDate = new FormData()
            if (values['email'] != '') {
                subsribeFormDate.append('subscribe_email', values['email'])
                subsribeFormDate.append('site_id', global.site_id)
                const res = apiCall('post', '', subsribeFormDate, '', 'hubspot-save')
                console.log('Result is____________', res)
            }
            //addCustomer(values)
            formik.values.email = ''
        },
    })

    const subscribe = [
        {
            label: 'Email',
            placeholder: 'Enter your email',
            class: 'subsInp',
            type: 'text',
            variant: 'filled',
            name: 'email',
            formik: formik,
            size: 'small',
        },
    ]

    useEffect(() => {
        getLangPhrase(language)
    }, [language])

    useEffect(() => {
        if (responseStatusAutoPlt) {
            if (responseStatusAutoPlt.from === 'addCustomer') {
                if (responseStatusAutoPlt.status === 'success') {
                    formik.values.email = ''
                }
            } else {
                console.log('Subscription Error', responseStatusAutoPlt)
            }
        }
    }, [responseStatusAutoPlt])

    return (
        <footer>
            <div className="footCnt">
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <div className="footLt d-flex justify-content-between align-items-center">
                        <div className="footLogo d-flex">
                            <img src={logo} alt={`${siteName} logo`} />
                        </div>
                        <div className="footLinks d-flex justify-content-between">
                            <ul>
                                <li>
                                    <NavLink
                                        to="/about"
                                        onClick={() => {
                                            window.scrollTo(0, 0)
                                        }}
                                    >
                                        {phrase.about}
                                    </NavLink>
                                </li>
                                <li>
                                    <a
                                        href="/support"
                                        onClick={() => {
                                            window.scrollTo(0, 0)
                                        }}
                                    >
                                        {phrase.support}
                                    </a>
                                </li>
                                {isAuthenticated ? (
                                    <li>
                                        <a href="/sitemap">{phrase.site_map}</a>
                                    </li>
                                ) : null}
                            </ul>
                        </div>
                    </div>
                    {/* <div className="footRt d-flex justify-content-between align-items-center">
                        <h3>STAY IN TOUCH</h3>
                        <div className="footSubscribe">
                            <form
                                onSubmit={formik.handleSubmit}
                                autoComplete="nofill"
                                className="form-inline footSubsForm"
                            >
                                {Object.values(mapData(subscribe))}
                                <Button className="btn" type="submit">
                                    {phrase.subscribe}
                                </Button>
                            </form>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="footCopyright container-fluid">
                <p className="supportNumber customContainer">
                    For Support click on Support and send an email or Call +40 374-948-899
                </p>
                <hr />
                <div className="customContainer d-flex align-items-center flex-wrap">
                    <p>© {new Date().getFullYear()} LedMax Electronics.</p>
                    <ul>
                        <li>
                            <Link
                                to="/privacy"
                                onClick={() => {
                                    window.scrollTo(0, 0)
                                }}
                            >
                                {' '}
                                {phrase.privacy_policy}
                            </Link>
                        </li>
                        {/* <li>
                            <Link
                                to="/privacy-shield"
                                onClick={() => {
                                    window.scrollTo(0, 0)
                                }}
                            >
                                {phrase.privacy_shield}
                            </Link>
                        </li> */}
                        <li>
                            <Link
                                to="/terms"
                                onClick={() => {
                                    window.scrollTo(0, 0)
                                }}
                            >
                                {phrase.terms_of_use}
                            </Link>
                        </li>
                    </ul>
                    {/* <a href="https://app.renugo.com/" target="_blank" rel="noreferrer">
                        Powered by Renugo.com
                    </a> */}
                </div>
            </div>
        </footer>
    )
}

export default Footer
