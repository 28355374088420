import React, { useState, useContext, useEffect, useRef } from 'react'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import { handleRedirectInternal } from '../../common/components'
import { useHistory, NavLink } from 'react-router-dom'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import './Home1.css'
import { logo, siteName } from '../../Utils'
import {
    Badge,
    Button,
    Divider,
    Drawer,
    ListItem,
    Menu,
    MenuItem,
    SwipeableDrawer,
} from '@material-ui/core'
import CommonContext from '../../context/common/commonContext'
import GridView from '../../components/molecules/ProductCard/GridView'
import Search from '../Search'
import { Pagination } from '@material-ui/lab'
import ListView from '../../components/molecules/ProductCard/ListView'
import Loader from '../../components/molecules/Loader'
import ProductContext from '../../context/product/productContext'

function Home1() {
    const history = useHistory()
    const productContext = useContext(ProductContext)
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
    }
    let [viewProduct, setViewProduct] = useState([])
    const {
        search_allproducts,
        getAllSearchProducts,
        responseStatus,
        getProjectsMain,
        projectsMain,
        getProjectsCompleted,
        projectCompleted,
        dispatchSelectedCategories,
        selectedCategories,
        projmainloading,
    } = productContext
    const { language, phrase, getLangPhrase } = useContext(CommonContext)

    useEffect(() => {
        getLangPhrase(language)
    }, [language])

    useEffect(() => {
        getAllSearchProducts({}, 'search')
    }, [])

    useEffect(() => {
        setViewProduct(search_allproducts)
    }, [search_allproducts])

    return (
        <div className="content-wrapper">
            <section className="wrapper image-wrapper">
                <div className="container content text-center">
                    <div className="row">
                        <div className="col-lg-8 col-xl-7 col-xxl-6 mx-auto">
                            <h1 className="wholesale text-white">WELCOME TO LEDMAX AUCTIONS</h1>
                            <p className="lead fs-24 text-white lh-sm mb-7 mx-md-13 mx-lg-10">
                                Worldwide desktop computers and components bidding platform
                            </p>
                            <Button className="readmore mt-4">
                                <NavLink to="/search" className="text-white">
                                    SEE LIVE AUCTIONS
                                </NavLink>
                            </Button>
                        </div>
                    </div>
                </div>
                {/* <div className="overflow-hidden shape">
                    <div className="divider text-light mx-n2">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 60">
                            <path
                                fill="currentColor"
                                d="M0,0V60H1440V0A5771,5771,0,0,1,0,0Z"
                            ></path>
                        </svg>
                    </div>
                </div> */}
            </section>
            <section className="wrapper bg-light">
                <div className="container pb-3">
                    <div className="row gx-md-5 pairs gy-5 mt-n17 mb-14 mb-md-17">
                        <div className="col-md-6 col-xl-3 d-flex justify-content-center">
                            <div className="card shadow-lg four">
                                <div className="card-body">
                                    <span className="material-icons">dashboard</span>
                                    <h2>150.000+</h2>
                                    <p>COMPUTERS IN STOCK</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3 d-flex justify-content-center">
                            <div className="card shadow-lg four">
                                <div className="card-body">
                                    <span className="material-icons">person</span>
                                    <h2>500+</h2>
                                    <p>HAPPY CUSTOMERS</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3 d-flex justify-content-center">
                            <div className="card shadow-lg four">
                                <div className="card-body">
                                    <span className="material-icons">workspaces</span>
                                    <h2>20+</h2>
                                    <p>TRADE BRANDS</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3 d-flex justify-content-center">
                            <div className="card shadow-lg four">
                                <div className="card-body">
                                    <span className="material-icons">webhook</span>
                                    <h2>10+</h2>
                                    <p>YEARS OF EXPERIENCE</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="wrapper bg-light auctions pb-5">
                <div className="inner-container">
                    <h2 className="mb-3">Our Auctions</h2>
                    <div>
                        {projmainloading ? (
                            <Loader />
                        ) : viewProduct.length ? (
                            <>
                                <div className={`searchResults ${auctionView}`}>
                                    

                                    {viewProduct.map((data, index) => (
                                        <React.Fragment key={index}>
                                            {auctionView === 'Grid' ? (
                                                <>
                                                    <GridView
                                                        data={data}
                                                        favId={`searchProd_${index}`}
                                                        // drawerHandler={toggleDrawer(
                                                        //     'right',
                                                        //     true,
                                                        //     data,
                                                        // )}
                                                    />
                                                </>
                                            ) : (
                                                <ListView
                                                    data={data}
                                                    favId={`searchProd_${index}`}
                                                    // drawerHandler={toggleDrawer(
                                                    //     'right',
                                                    //     true,
                                                    //     data,
                                                    // )}
                                                />
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>

                                <div className="d-flex justify-content-between align-items-center flex-wrap w-100 my-3 pagination-wrapper">
                                    <Pagination
                                        count={Math.ceil(
                                            projectsMain.totalRecords /
                                                projectsMainPayload.sh_limit,
                                        )}
                                        page={projectsMainPayload.page}
                                        onChange={onHandlePage}
                                        siblingCount={3}
                                        showFirstButton
                                        showLastButton
                                        boundaryCount={2}
                                    />
                                </div>
                            </>
                        ) : (
                            <div className="np-pro-box">
                                <h5>{phrase.no_results_found}!</h5>
                            </div>
                        )}
                    </div>
                </div>
            </section> */}
            <section className="wrapper computers bg-light pb-5">
                <div className="inner-container">
                    <h6 className="world mb-3">WORLD OF COMPUTERS</h6>
                    <h2 className="mb-5">Why Choose Us</h2>
                    <div className="comp_parts">
                        <div className="part">
                            <span className="material-icons">inventory</span>
                            <h3>Huge Stock</h3>
                            <p>
                                +150.000pcs desktop computers
                                <br />
                                +700.000pcs computer components
                            </p>
                        </div>
                        <div className="part">
                            <span className="material-icons">payment</span>
                            <h3>Best Prices</h3>
                            <p>
                                We help you get the best deals on the market, so you&apos;ll save on
                                your order.
                            </p>
                        </div>
                        <div className="part">
                            <span className="material-icons">category</span>
                            <h3>Large Quantities Supply</h3>
                            <p>
                                We are a wholesaler, which means we can provide you computer systems
                                in large quantities
                            </p>
                        </div>
                        {/* <div className="part">
                            <span className="material-icons">computer</span>
                            <h3>Computer Built Request</h3>
                            <p>
                                We are more than happy to assist your needs, so we&apos;ll build
                                computer systems with the specifications you want.
                            </p>
                        </div> */}
                        <div className="part">
                            <span className="material-icons">public</span>
                            <h3>Worldwide Shipping</h3>
                            <p>
                                We can ship your order worldwide - by road, by sea, by air, express
                                or economic system, with the best carriers.
                            </p>
                        </div>
                        {/* <div className="part">
                            <span className="material-icons">cast</span>
                            <h3>Warranty</h3>
                            <p>
                                All our products are covered by warranty, depending on the
                                product&apos;s type, category and condition
                            </p>
                        </div> */}
                    </div>
                </div>
            </section>
            {/* <section className="wrapper bg-pink">
                <div className="container py-15 py-md-17 pb-md-19">
                    <h6 className="world mb-3">18 YEARS OF EXPERIENCE</h6>
                    <h2 className="mb-5">
                        LedMax is a global wholesale distributor of refurbished and used desktop
                        computers equipment and components.
                    </h2>
                    <div className="card shadow-lg">
                        <div className="row gx-0">
                            <div className="col-lg-6">
                                <div className="bg-image bg-cover rounded-top rounded-lg-start new-image"></div>
                            </div>
                            <div className="col-lg-6">
                                <div className="p-5">
                                    <h3 className="my-4">Welcome to LedMax</h3>
                                    <p>
                                        Your source for used and refurbished Desktop Computers & All
                                        in Ones Systems. In addition to used wholesale computers, we
                                        sell computer parts such as CPUs, RAMs, HDDs, SSDs, GPUs,
                                        Motherboards, Optical Drives, Power Supplies, Power
                                        Adapters, e.t.c.
                                    </p>
                                    <Button className="readmore my-4">
                                        <NavLink to="/about" className="text-white">
                                            Read More
                                        </NavLink>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </div>
    )
}

export default Home1
