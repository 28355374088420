import React, { useContext, useEffect, useRef } from 'react'
import './FilterPanel.css'
import { useHistory } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CheckBox from '../../atoms/CheckBox'
import RadioBox, { GreenRadio } from '../../atoms/RadioBox'
import ProductContext from '../../../context/product/productContext'
import CommonContext from '../../../context/common/commonContext'
import { Button, MenuItem } from '@material-ui/core'
import { useFormik } from 'formik'

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion)
const AccordionSummary = withStyles({
    root: {
        // backgroundColor: 'transparent',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 50,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary)
const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails)

const FilterPanel = (props) => {
    const {
        search_alldashboardproducts,
        search_allproducts,
        selectedCategories,
        search_allselectedCategories,
    } = useContext(ProductContext)
    const { setSearchValue, phrase, language } = useContext(CommonContext)
    const [expanded, setExpanded] = React.useState('panel0')
    const history = useHistory()

    const selectedCatRef = useRef(selectedCategories)

    // Initialize Filter Values
    let filtervalues = [
        {
            title: phrase.category,
            type: 'check',
            name: 'category',
            item: [],
            key: 'category',
        },
        {
            title: phrase.sub_category,
            type: 'check',
            name: 'sub_category',
            item: [],
            key: 'sub_category',
        },
        {
            title: phrase.condition,
            type: 'check',
            name: 'condition',
            item: [],
            key: 'itemcondition',
        },
        {
            title: phrase.listing_format,
            type: 'check',
            name: 'listing',
            item: [],
            key: 'auctiontype',
        },
        // {
        //     title: phrase.regions,
        //     type: 'check',
        //     name: 'region',
        //     item: [],
        //     key: 'regionlist',
        // },
        {
            title: phrase.lot_size,
            type: 'check',
            name: 'lot',
            item: [],
            key: 'lottype',
        },
    ]
    const [filterValues, setFiltervalues] = React.useState(filtervalues)

    const sortValues = props.sorts
    const formik = useFormik({
        initialValues: {
            category: [],
            sub_category: [],
            banner: [],
            category: [],
            countrylist: [],
            itemcondition: [],
            lottype: [],
            regionlist: [],
        },
    })

    // language changed, update filter labels
    // const phraseRef = useRef(phrase)
    // useEffect(() => {
    //     console.log('lang changed___________', language)
    //     //phraseRef.current = phrase
    //     setFiltervalues([...filterValues])
    // }, [phrase])

    useEffect(() => {
        console.log('SEARCH FILTERS ===== ', search_allproducts)
        if (
            typeof search_allproducts !== 'undefined' &&
            Object.keys(search_allproducts).length > 0
        ) {
            if (typeof filterValues !== 'undefined' && Array.isArray(filterValues)) {
                let filterValuesCopy = [...filterValues]
                let searchProductsCopy = { ...search_allproducts }
                filterValuesCopy = filterValuesCopy.map((ele) => {
                    // console.log(ele, 'ele....')
                    // Lot Size / Listing Format Filtering
                    // Filter by Name
                    // lottype = Lot Size
                    // auctiontype = Listing Format
                    if (ele.key === 'lottype' || ele.key === 'auctiontype') {
                        if (
                            Array.isArray(searchProductsCopy[ele.key]) &&
                            searchProductsCopy[ele.key].length
                        ) {
                            searchProductsCopy[ele.key] = searchProductsCopy[ele.key].map(
                                (ele1, idx) => {
                                    let labelTemp = phrase[ele1]

                                    // modify listing format label name
                                    if (ele.key == 'auctiontype') {
                                        if (ele1 == 'pricelock') {
                                            labelTemp = `${phrase.price_lock}`
                                        } else if (ele1 == 'buynow') {
                                            labelTemp = `${phrase.buy_it_now}`
                                        } else {
                                            labelTemp = `${phrase[ele1]} ${phrase.auction}`
                                        }
                                    }

                                    return {
                                        id: ele.key + idx,
                                        name: ele1,
                                        filterBy: ele1,
                                        label: labelTemp,
                                    }
                                },
                            )
                        }
                    }
                    // Item Condition / Category / Regions Filtering
                    // Filter by ID
                    else if (
                        ele.key === 'itemcondition' ||
                        ele.key === 'category' ||
                        ele.key === 'regionlist' ||
                        ele.key === 'sub_category'
                    ) {
                        if (
                            Array.isArray(searchProductsCopy[ele.key]) &&
                            searchProductsCopy[ele.key].length
                        ) {
                            searchProductsCopy[ele.key] = searchProductsCopy[ele.key].map(
                                (ele1) => {
                                    // console.log('FILTER BY ID: ', ele1)
                                    let nameTemp = ''
                                    if (ele.key === 'regionlist') {
                                        nameTemp = ele1.region_eng ? ele1.region_eng : ele1.name
                                    } else {
                                        nameTemp = phrase[ele1.language_var]
                                            ? phrase[ele1.language_var]
                                            : ele1.name
                                    }
                                    return {
                                        id: ele.key + ele1.id,
                                        filterBy: ele1.id,
                                        name: nameTemp,
                                    }
                                },
                            )
                        }
                    }

                    ele.item = searchProductsCopy[ele.key]
                    return ele
                })
                setFiltervalues(filterValuesCopy)
            }
        }
    }, [search_allproducts])
    console.log(search_allproducts, 'search_allproducts')
    useEffect(() => {
        //console.log('categories changed: ', selectedCategories)
        selectedCatRef.current = selectedCategories
        setFiltervalues([...filterValues])
    }, [selectedCategories])

    const handleChange = (panel) => (event, newExpanded) => {
        console.log(panel, 'console')
        if (panel === expanded && !newExpanded) {
            setExpanded(newExpanded ? panel : false)
        } else {
            setExpanded(newExpanded ? panel : false)
        }
    }
    const checkboxHandleChange = (data, d) => (event) => {
        // pricelock auction, take them to price lock page
        if (d.filterBy == 'pricelock') {
            history.push('/price_lock')
        } else {
            let { value, name } = event.target
            let sObj = {
                id: value,
                name,
                type: data.name,
                filterBy: d.filterBy,
            }
            console.log('SOBJ: ', sObj)
            if (d.hasOwnProperty('country')) sObj['country'] = d.country
            props.searchFilterFunc(sObj)
        }
    }
    const filterChange = (event) => {
        console.log(event, 'console Evenet')
        let { value, name } = event.target
    }
    const isChecked = (data) => {
        //console.log('isChecked________', selectedCatRef.current, '________data_______', data)
        if (Array.isArray(selectedCatRef.current) && selectedCatRef.current.length) {
            let selInd = selectedCatRef.current.findIndex((ele) => {
                return ele.id == data.id
            })
            if (selInd >= 0) {
                return true
            }
        }
        return false
    }
    console.log(filterValues, 'filterValues')
    return (
        <div className="filterPanel">
            <h6 className="accTitleNew">{phrase.filter_by}</h6>

            {selectedCategories &&
            Array.isArray(selectedCategories) &&
            selectedCategories.length ? (
                <>
                    <div className="filterTagsCnt">
                        {selectedCategories.map((cate, index) => {
                            return (
                                <div
                                    className="filterTagsNew"
                                    key={index}
                                    onClick={(e) => {
                                        props.searchFilterFunc({
                                            id: cate.id,
                                            name: cate.name,
                                        })
                                    }}
                                >
                                    {cate.name}
                                    <span className="material-icons">cancel</span>
                                </div>
                            )
                        })}
                    </div>
                </>
            ) : null}
            <div className="d-flex filterCntNew align-items-center">
                <Button
                    className="filterClear filterClearNew"
                    onClick={(e) => props.searchFilterFunc({}, true)}
                >
                    {phrase.clear} {phrase.all}
                </Button>
            </div>
            {/* <div className="d-flex fpTitle">
                <h4>{phrase.filter_by}</h4>
            </div> */}
            <div className="filterAcc">
                {filterValues &&
                    Array.isArray(filterValues) &&
                    filterValues.map((data, index) => (
                        <Accordion
                            square
                            key={index}
                            expanded={
                                expanded === `panel${index}`
                                // || formik.values[data.name].length > 0
                            }
                            onChange={handleChange(`panel${index}`)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index}d-content`}
                                id={`panel${index}d-header`}
                                onChange={filterChange}
                            >
                                <h6 className="accTitle">
                                    {data
                                        ? data.key === 'category'
                                            ? phrase.model || 'Category'
                                            : data.key === 'itemcondition'
                                            ? phrase.grade || 'Grade'
                                            : data.key === 'auctiontype'
                                            ? phrase.listing_format
                                            : data.key === 'regionlist'
                                            ? phrase.regions
                                            : data.key === 'lottype'
                                            ? phrase.lot_size
                                            : data.key === 'sub_category'
                                            ? phrase.sub_category || 'Sub category'
                                            : null
                                        : null}
                                </h6>
                            </AccordionSummary>
                            <AccordionDetails>
                                {data && data.item && data.item.length > 0 ? (
                                    <div className="filterCheck">
                                        {data && data.type === 'check' ? (
                                            data.item.map((d, i) => (
                                                <React.Fragment key={i}>
                                                    <div className="filterCB">
                                                        <CheckBox
                                                            name={d.name}
                                                            label={d.label ? d.label : d.name}
                                                            value={d.id}
                                                            checked={isChecked(d)}
                                                            onchange={checkboxHandleChange(data, d)}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <>
                                                <RadioBox
                                                    items={data ? data.item : ''}
                                                    value={formik.values[data ? data.name : '']}
                                                    onchange={formik.handleChange}
                                                    name={data ? data.name : ''}
                                                    int={1}
                                                />
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    <small>{`No ${data ? data.title : ''} found`}</small>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    ))}
            </div>
        </div>
    )
}
export default FilterPanel
