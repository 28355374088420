import React, { useState, useContext, useEffect } from 'react'
import { Button, Divider, ListItem } from '@material-ui/core'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import CustomInput from '../../atoms/Inputs/CustomInput'
import './SearchNav.css'
import SearchIcon from '@material-ui/icons/Search'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import { handleRedirectInternal } from '../../../common/components'
import ReactHtmlParser from 'react-html-parser'
import AuthContext from '../../../context/auth/authContext'
import CommonContext from '../../../context/common/commonContext'
import ProductContext from '../../../context/product/productContext'

const SearchNav = (props) => {
    const authContext = useContext(AuthContext)
    const { user } = authContext
    const { pathname } = useLocation()
    const [categoryAnchor, setCategoryAnchor] = useState(null)
    const [conditionAnchor, setConditionAnchor] = useState(null)
    const [categoryMenu, setCategoryMenu] = useState([])
    const [conditionMenu, setConditionMenu] = useState([])

    const history = useHistory()
    const { phrase, staticPages, language, getSitemap, setSearchValue, searchValue } =
        useContext(CommonContext)
    const { dispatchSelectedCategories } = useContext(ProductContext)

    useEffect(async () => {
        // get static content like categories, conditions
        await getSitemap()
    }, [language])

    useEffect(() => {
        // category loaded
        if (staticPages.categories) {
            let categories = staticPages.categories.map((cat) => {
                return {
                    label: phrase[cat.language_var] ? phrase[cat.language_var] : cat.name,
                    id: cat.id,
                }
            })
            setCategoryMenu(categories)
        }
        // conditions loaded
        if (staticPages.condition) {
            //  {$_phrase.{"`$val.language_var`_cond_content"}}
            let conditions = staticPages.condition.map((cond) => {
                return {
                    title: phrase[cond.language_var],
                    info: phrase[`${cond.language_var}_cond_content`],
                    id: cond.id,
                }
            })
            setConditionMenu(conditions)
        }
    }, [staticPages])

    const setCategoryOpen = (event) => {
        setCategoryAnchor(event.currentTarget)
    }

    // category clicked
    const setCategoryClose = (type, data) => (event) => {
        if (typeof type !== 'undefined') {
            if (typeof data !== 'undefined') {
                if (props.searchFilterFunc) {
                    props.searchFilterFunc({
                        id: 'category' + data.id,
                        type,
                        name: data.label,
                        filterBy: data.id,
                    })
                }
                // not on search page
                else {
                    let selectedCatPayload = []
                    selectedCatPayload.push({
                        id: 'category' + data.id,
                        name: data.label,
                        type: 'category',
                        filterBy: data.id,
                    })
                    dispatchSelectedCategories(selectedCatPayload)
                    history.push('/search')
                }
            }
        }
        setCategoryAnchor(null)
    }

    const setConditionOpen = (event) => {
        setConditionAnchor(event.currentTarget)
    }

    // condition clicked
    const setConditionClose = (type, data) => (event) => {
        if (typeof type !== 'undefined') {
            if (typeof data !== 'undefined') {
                if (props.searchFilterFunc) {
                    props.searchFilterFunc({
                        id: 'itemcondition' + data.id,
                        type,
                        name: data.title,
                        filterBy: data.id,
                    })
                }
                // not on search page
                else {
                    let selectedConPayload = []
                    selectedConPayload.push({
                        id: 'itemcondition' + data.id,
                        name: data.title,
                        type: 'condition',
                        filterBy: data.id,
                    })
                    dispatchSelectedCategories(selectedConPayload)
                    history.push('/search')
                }
            }
        }
        setConditionAnchor(null)
    }

    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip)

    const newlyAddedClick = () => {
        let eve = {
            target: {
                value: 2,
            },
        }
        if (props.sortByChange) {
            props.sortByChange(eve)
        }
        // not on search page
        else {
            setSearchValue({ ...searchValue, orderby: 2 })
            history.push('/search')
        }
    }

    const handleSearchBarValue = (event) => {
        let { key } = event
        let { value } = event.target
        setSearchValue({ ...searchValue, searchbar: value })
        if (key === 'Enter') {
            history.push('/search')
        }
    }

    // user hit on search icon on top bar
    const searchBarSubmit = () => {
        setSearchValue({ ...searchValue, searchbar: searchValue.searchbar })
        history.push('/search')
    }

    return (
        <div className="searchNav">
            <div className="searchNavCnt customContainer d-flex justify-content-end align-items-center">
                <ul className="d-flex justify-content-start align-items-center">
                    {/* {user.isBuyer ? (
                        <>
                            <ListItem>
                                <NavLink
                                    activeClassName="active"
                                    to="/dashboard/active"
                                    isActive={() =>
                                        [
                                            '/dashboard/active',
                                            '/dashboard/buy_now',
                                            '/dashboard/won',
                                            '/dashboard/lost',
                                        ].includes(pathname)
                                    }
                                >
                                    {phrase.my_auctions}
                                </NavLink>
                            </ListItem>
                            <ListItem>
                                <NavLink activeClassName="active" to="/dashboard/watchlist">
                                    {phrase.watch_list}
                                </NavLink>
                            </ListItem>
                        </>
                    ) : null} */}
                    <ListItem>{phrase.shop_by}</ListItem>
                    <ListItem
                        button
                        aria-controls="categories"
                        aria-haspopup="true"
                        onClick={setCategoryOpen}
                    >
                        {phrase.categories}
                        <span className="material-icons">arrow_drop_down</span>
                    </ListItem>
                    <ListItem
                        button
                        aria-controls="conditions"
                        aria-haspopup="true"
                        onClick={setConditionOpen}
                    >
                        {phrase.condition}
                        <span className="material-icons">arrow_drop_down</span>
                    </ListItem>
                    {props.completed ? null : (
                        <ListItem button onClick={newlyAddedClick}>
                            {phrase.newly_added}
                        </ListItem>
                    )}
                    {/* <ListItem button onClick={() => handleRedirectInternal(history, 'price_lock')}>
                        {phrase.price_lock_auction}
                    </ListItem> */}
                </ul>
                <Menu
                    id="categories"
                    anchorEl={categoryAnchor}
                    keepMounted
                    open={Boolean(categoryAnchor)}
                    onClose={() => setCategoryAnchor(null)}
                    className="customDropdown"
                >
                    <div className="categoryMenu">
                        {categoryMenu.map((data, index) => (
                            <React.Fragment key={index}>
                                <MenuItem onClick={setCategoryClose('category', data)} key={index}>
                                    {/* <img src={data.img} /> */}
                                    <h5>{data.label}</h5>
                                </MenuItem>
                                <Divider />
                            </React.Fragment>
                        ))}
                    </div>
                </Menu>
                <Menu
                    id="conditions"
                    anchorEl={conditionAnchor}
                    keepMounted
                    open={Boolean(conditionAnchor)}
                    onClose={() => setConditionAnchor(null)}
                    className="customDropdown"
                >
                    <div className="conditionsMenu">
                        {conditionMenu.map((data, index) => (
                            <React.Fragment key={index}>
                                <LightTooltip title={ReactHtmlParser(data.info)}>
                                    <MenuItem
                                        onClick={setConditionClose('condition', data)}
                                        key={index}
                                    >
                                        <h3>{ReactHtmlParser(data.title)}</h3>
                                        <h5>{ReactHtmlParser(data.info)}</h5>
                                    </MenuItem>
                                </LightTooltip>
                                <Divider />
                            </React.Fragment>
                        ))}
                    </div>
                </Menu>
                <div className="searchBox">
                    <CustomInput
                        label={phrase.search_inventory}
                        size="small"
                        placeholder={phrase.search_inventory}
                        defaultValue={
                            props.searchBarValue
                                ? props.searchBarValue
                                : searchValue.searchbar
                                ? searchValue.searchbar
                                : ''
                        }
                        value={props.searchBarValue}
                        onChange={props.searchInv ? props.searchInv : handleSearchBarValue}
                        onKeyDown={props.searchInv ? props.searchInv : handleSearchBarValue}
                        endadornment={
                            <Button
                                type="button"
                                onClick={
                                    props.searchBarSubmit ? props.searchBarSubmit : searchBarSubmit
                                }
                            >
                                <SearchIcon />
                            </Button>
                        }
                    />
                </div>
            </div>
        </div>
    )
}

export default SearchNav
