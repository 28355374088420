import React, { useState, useContext, useEffect } from 'react'
import './Login.css'
import { NavLink, useHistory } from 'react-router-dom'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import SecondaryButton from '../../components/atoms/SecondaryButton'
import CheckBox from '../../components/atoms/CheckBox'
import { Link } from 'react-router-dom'
import { Button, Divider } from '@material-ui/core'
import { mapData, handleRedirectInternal } from '../../common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AuthContext from '../../context/auth/authContext'
import AlertContext from '../../context/alert/alertContext'
import CommonContext from '../../context/common/commonContext'
import { logo, siteName } from '../../Utils'
import Popup from '../../components/organisms/Popup'

const Login = () => {
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)

    const { setAlert } = alertContext
    const { phrase } = useContext(CommonContext)

    const { login, responseStatus, clearResponse, isAuthenticated } = authContext

    const params = new URLSearchParams(window.location.search.slice(1))
    useEffect(() => {
        // if (isAuthenticated) {
        //     handleRedirectInternal(history, '')
        // }
    }, [isAuthenticated])
    const rememberedTokenLoc = localStorage.getItem('remembered')
    let [passwordShown, setPasswordShown] = useState(false)
    const [modal, setPopup] = useState(false)
    let [rememberMeChecked, setRememberMeChecked] = useState(rememberedTokenLoc ? true : false)
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }
    const openPopup = () => {
        setPopup(true)
    }
    const closePopup = () => {
        setPopup(false)
    }

    useEffect(() => {
        if (params.get('auth') == 1) {
            openPopup()
        }
    }, [window.location.search])
    const validationArray = Yup.object({
        email: Yup.string()
            .email(phrase.invalid_format)
            .required(`${phrase.enter} ${phrase.email_address}`),
        password: Yup.string().required(`${phrase.enter} ${phrase.password}`),
    })

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            let payload = {
                username: values.email,
                password: values.password,
                site_id: global.site_id,
            }
            let data = await login(payload)
            if (rememberMeChecked) {
                let rememberedUserToken = btoa(JSON.stringify(values))
                localStorage.setItem('remembered', rememberedUserToken)
            } else {
                localStorage.removeItem('remembered')
            }
            if (data.returnval === 3) handleRedirectInternal(history, 'search')
            if (data.returnval == 5)
                handleRedirectInternal(history, `afterregister/${data.linkafter}`)
        },
    })

    useEffect(() => {
        formik.setTouched({
            ...formik.touched,
        })
    }, [localStorage.getItem('language')])

    const loginInfo = [
        {
            label: phrase.email_address,
            name: 'email',
            type: 'email',
            placeholder: `${phrase.enter} ${phrase.email_address}`,
            class: 'col-12',
            autoFocus: true,
            formik: formik,
        },
        {
            label: phrase.password,
            name: 'password',
            type: passwordShown ? 'text' : 'password',
            placeholder: `${phrase.enter} ${phrase.password}`,
            class: 'col-12',
            formik: formik,
            endadornment: passwordShown ? (
                <span className="material-icons cursorPointer" onClick={togglePasswordVisiblity}>
                    visibility_off
                </span>
            ) : (
                <span className="material-icons cursorPointer" onClick={togglePasswordVisiblity}>
                    visibility
                </span>
            ),
        },
    ]

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'login') {
                setAlert(responseStatus.message, responseStatus.status)
                clearResponse()
                if (responseStatus.status === 'success') {
                    handleRedirectInternal(history, 'search')
                }
            }
        }
    }, [responseStatus])
    // useEffect(() => {
    //     if (rememberMeChecked) {
    //         let rememberedUserToken = btoa(JSON.stringify(formik.values))
    //         localStorage.setItem('remembered', rememberedUserToken)
    //     } else {
    //         localStorage.removeItem('remembered')
    //     }
    // }, [rememberMeChecked])
    useEffect(() => {
        if (rememberedTokenLoc) {
            let decriptedValue = atob(rememberedTokenLoc)
            decriptedValue = JSON.parse(decriptedValue)
            formik.setFieldValue('email', decriptedValue.email)
            formik.setFieldValue('password', decriptedValue.password)
        }
    }, [])
    const rememberMe = () => {
        setRememberMeChecked(!rememberMeChecked)
    }

    return (
        <div className="loginContainer">
            <div className="bg-login"></div>
            <div className="log-content">
                {isAuthenticated ? (
                    <a onClick={() => redirectDefaultSearch()}>
                        <img src={logo} alt={`${siteName} logo`} />
                    </a>
                ) : (
                    <Link to="/">
                        <img src={logo} alt={`${siteName} logo`} />
                    </Link>
                )}
                <div className="login">
                    {/* <h2 className="loginTitle">
                    <span>{phrase.login}</span>
                </h2> */}
                    <h1 className="text-left mb-2 welcome">Welcome back</h1>
                    <div className="d-flex align-items-center mb-4 new">
                        <p className="mr-2">{phrase.new_to_ledmax}?</p>
                        <p className="acc">
                            <NavLink to="/register">{phrase.create_an_account}.</NavLink>
                        </p>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">{Object.values(mapData(loginInfo))}</div>
                        <div className="d-flex loginActBox justify-content-between align-items-center">
                            <CheckBox
                                label={phrase.remember_me}
                                onchange={rememberMe}
                                checked={rememberMeChecked}
                            />
                            <Link to="/forgot_password">{phrase.forgot_your_password}</Link>
                        </div>
                        <PrimaryButton label={phrase.login} type="submit" />
                    </form>
                    {/* <div className="loginMiscAction">
                        <p>
                            {phrase.dont_have_an_account}{' '}
                            <Link to="/register"> {phrase.sign_up} </Link>
                        </p>
                    </div> */}
                    <Popup
                        open={modal}
                        handleClose={closePopup}
                        modaltitle={'Discover unique items and exciting auctions.'}
                    >
                        <p>
                            <Link to="/register">Sign up</Link> to gain full access to all our
                            auctions and start bidding today!
                        </p>
                        <p>Full access to all auctions.</p>
                        <p>Ability to place bids and win items.</p>
                        <p>Stay updated with the latest auctions and items.</p>
                    </Popup>
                </div>
            </div>
        </div>
    )
}

export default Login
