import React from 'react'
export const messageHandler = (data, product, user, setAlert, setViewProduct, type) => {
    let user_id = user.id
    const index = product.findIndex((s) => s.id === parseInt(data.id || data.product_id, 10))
    let productToChange = product[index]
    console.log(productToChange, '+++++', type)
    if (index !== -1) {
        if (type === 'realclosedupdates') {
            if (data.usr !== '') {
                if (user_id === parseInt(data.bpop_cbidder)) {
                    product[index] = {
                        ...productToChange,
                        market_status: 'sold',
                        bidtopstatus: 'won',
                        buynowamount: data.buynowamount,
                        cbidtext: 'Sold Price',
                    }
                    setViewProduct([...product])
                } else if (parseInt(productToChange.bid_or_not) > 0) {
                    product[index] = {
                        ...productToChange,
                        market_status: 'sold',
                        bidtopstatus: 'lost',
                        buynowamount: data.buynowamount,
                        cbidtext: 'Sold Price',
                    }
                    setViewProduct([...product])
                } else {
                    product[index] = {
                        ...productToChange,
                        market_status: 'sold',
                        buynowamount: data.buynowamount,
                        cbidtext: 'Sold Price',
                    }
                    setViewProduct([...product])
                }
            } else {
                if (parseInt(productToChange.bid_or_not) > 0) {
                    product[index] = {
                        ...productToChange,
                        market_status: 'closed',
                        bidtopstatus: 'lost',
                    }
                    setViewProduct([...product])
                } else {
                    product[index] = {
                        ...productToChange,
                        market_status: 'closed',
                    }
                    setViewProduct([...product])
                }
            }
        } else if (type === 'bidAddtime') {
            if (parseInt(data.bpop_belowFive) === 1) {
                // updatecloseDate(data.bpop_belowFiveIncrement)
            }
            if (data.status === 'failed' && user_id === parseInt(data.bpop_cbidder)) {
                setAlert(data.error, 'error')
            }
            if (data.status !== 'failed') {
                if (parseInt(data.bpop_belowFive) === 1) {
                    productToChange.date_closed = data.bpop_belowFiveIncrement
                }
                if (user_id === parseInt(data.bpop_cbidder)) {
                    setAlert('Bid placed successfully', 'success')
                    product[index] = {
                        ...productToChange,
                        next_bid: data.bpop_cuser_nextbid,
                        wprice: data.bpop_wprice,
                        bidtopstatus: data.bpop_bidstatus,
                        bid_count: data.bpop_bidcount,
                        bid_or_not: 1,
                        cbidtext: 'Current Bid',
                        latestbid: data.bpop_wprice,
                    }
                    setViewProduct([...product])
                } else {
                    console.log('coming inside 3333')
                    if (parseInt(productToChange.bid_or_not) > 0) {
                        console.log('coming inside 55555', user_id, productToChange, data)
                        if (user_id === parseInt(data.bpop_higher)) {
                            console.log('123123213', parseInt(productToChange.next_bid))
                            if (parseInt(productToChange.next_bid) < parseInt(data.bpop_nextbid)) {
                                console.log('faasdfsadf')
                                product[index] = {
                                    ...productToChange,
                                    next_bid: data.bpop_nextbid_org,
                                    wprice: data.bpop_wprice,
                                    bidtopstatus: 'winner',
                                    bid_count: data.bpop_bidcount,
                                    latestbid: data.bpop_wprice,
                                }
                                setViewProduct([...product])
                            } else {
                                product[index] = {
                                    ...productToChange,
                                    wprice: data.bpop_wprice,
                                    bidtopstatus: 'winner',
                                    bid_count: data.bpop_bidcount,
                                    latestbid: data.bpop_wprice,
                                }
                                setViewProduct([...product])
                            }
                        } else {
                            product[index] = {
                                ...productToChange,
                                next_bid: data.bpop_nextbid_org,
                                wprice: data.bpop_wprice,
                                bid_count: data.bpop_bidcount,
                                bidtopstatus: 'outbid',
                                bid_or_not: 1,
                                latestbid: data.bpop_wprice,
                            }
                            setViewProduct([...product])
                        }
                    } else {
                        console.log('coming inside 6777777', data)
                        product[index] = {
                            ...productToChange,
                            next_bid: data.bpop_nextbid_org,
                            wprice: data.bpop_wprice,
                            bid_count: data.bpop_bidcount,
                            cbidtext: 'Current Bid',
                            latestbid: data.bpop_wprice,
                        }
                        setViewProduct([...product])
                    }
                }
                // getBidHistoryProduct({ product_id: project_id })
            }
        } else if (type === 'bid_extended') {
            console.log(data, '++++++')
            product[index] = {
                ...productToChange,
                date_closed: data.date_extended,
            }
            setViewProduct([...product])
        } else if (type === 'watchlistAdded') {
            product[index] = {
                ...productToChange,
                watchlistid: data.status === 'added' ? 1 : 0,
            }
            setViewProduct([...product])
        } else if (type === 'drop_price') {
            product[index] = {
                ...productToChange,
                sprice: data.sprice,
                wprice: data.sprice,
            }
            setViewProduct([...product])
        }
    }
}

// bid socket handler for live / blind (SINGLE) auction
export const bidSocketHandler = (data, type, user, product, phrase, setProduct, setAlert) => {
    let productToChange = { ...product }
    //console.log('BID SOCKET HANDLER UESR ID: ', user)
    console.log('bid socket event_______', data)
    // AUCTION CLOSE
    if (type === 'realclosedupdates') {
        if (data.pid == product.id) {
            // This Item was awarded to somebody
            if (data.usr != '') {
                productToChange.market_status = 'sold'
                // This User or Company won the item
                if (
                    data.usr == user.id ||
                    (user.companyid == data.cid &&
                        data.cid &&
                        data.cid != '' &&
                        data.cid != '0' &&
                        user.companyid &&
                        user.companyid != '' &&
                        user.companyid != '0')
                ) {
                    productToChange.bidMessage = `<div class="padboth primeColor"> ${phrase.congratulations_you_have} <strong>${phrase.won}</strong> ${phrase.this_item} </div>`
                    setProduct(productToChange)
                }
                // This User or Company did not win the item
                else {
                    // This User or Company has bid on this item, but did not win=> Show Message
                    if (
                        parseInt(productToChange.userHasBid) > 0 ||
                        parseInt(productToChange.companyHasBid)
                    ) {
                        productToChange.bidMessage = `<div class="padboth" style="color: red;"> ${phrase.unfortunately_you} <strong>${phrase.lost}</strong> ${phrase.this_item} </div>`
                        setProduct(productToChange)
                    } else {
                        setProduct(productToChange)
                    }
                }
            }
            // no users were awarded
            else {
                // This User or Company has bid on this item => Show Message
                if (
                    parseInt(productToChange.userHasBid) > 0 ||
                    parseInt(productToChange.companyHasBid)
                ) {
                    productToChange.market_status = 'closed'
                    productToChange.bidMessage = `<div class="padboth" style="color: red;"> ${phrase.unfortunately_you} <strong>${phrase.lost}</strong> ${phrase.this_item} </div>`
                    setProduct(productToChange)
                } else {
                    productToChange.market_status = 'closed'
                    setProduct(productToChange)
                }
            }
        }
    }
    // BID EVENT
    else if (type === 'bidAddtime') {
        if (data.id == product.id) {
            // bid failed
            if (data.status === 'failed' && user.id === parseInt(data.bpop_cbidder)) {
                setAlert(data.error, 'error')
            }
            // bid success
            if (data.status !== 'failed') {
                if (parseInt(data.bpop_belowFive) === 1) {
                    productToChange.date_closed = data.bpop_belowFiveIncrement
                }
                // out bid message template
                productToChange.bid_count = data.bpop_bidcount
                let outbidMsg
                if (data.auctiontype == 'sealed') {
                    // blind auction
                    outbidMsg = `<div style="color: red;"> ${phrase.you_are_not_the_highest_bidder}</div>`
                } else {
                    // live auction
                    outbidMsg = `<div style="color: red;"> ${phrase.youre_losing_this_item_user} ${
                        data.bpop_biddersname.charAt(0) +
                        '****' +
                        data.bpop_biddersname.charAt(data.bpop_biddersname.length - 1)
                    } ${phrase.has_placed_a_higher} ${phrase.bid}.</div>`
                }

                // highest bidder message template
                const highestBidderMsg = `<div class="primeColor"> ${phrase.youre_winning_this_item_at} ${product.currency_symbol} ${data.bpop_wprice}. ${phrase.your_autobid_of} ${product.currency_symbol} ${data.bpop_wprice_morehigh} ${phrase.has_been_placed_successfully}</div>`
                // This user just bid on the item
                if (user.id === parseInt(data.bpop_cbidder)) {
                    //console.log('THIS USER BID')
                    setAlert('Bid placed successfully', 'success')
                    productToChange.next_bid = data.bpop_cuser_nextbid
                    productToChange.wprice = data.bpop_wprice
                    productToChange.buyerPrice = data.cuser_enteramount
                    if (data.auctiontype == 'live') {
                        productToChange.bidMessage = data.bpop_bidstatus
                    } else {
                        console.log('bid submit event___', data)
                        console.log('current bidder___', user.id)
                        if (user.id === parseInt(data.bpop_higher)) {
                            productToChange.bidMessage = ''
                        } else {
                            productToChange.bidMessage = outbidMsg
                        }
                    }
                    productToChange.userHasBid = 1
                    productToChange.cbidtext = 'Current Bid'
                    if (data.auctiontype == 'sealed')
                        productToChange.blindIncrementVal = data.bpop_cuser_increment
                    setProduct(productToChange)
                }
                // Other user bid
                else {
                    // This user has a bid in this item
                    if (parseInt(productToChange.userHasBid) > 0) {
                        // other user has bid, but this user is still the highest
                        if (user.id === parseInt(data.bpop_higher)) {
                            // LIVE AUCTION
                            // This user's autobid was placed and is still the highest.
                            // But, this user's max amount has been reached.
                            if (data.auctiontype == 'live') {
                                //console.log('NEXT BID: ', productToChange.next_bid)
                                //console.log('BPOP NEXT BID: ', data.bpop_nextbid)
                                if (
                                    parseInt(productToChange.next_bid) < parseInt(data.bpop_nextbid)
                                ) {
                                    //console.log('USER MAX PRICE REACHED')
                                    productToChange.next_bid = data.bpop_nextbid_org
                                    productToChange.wprice = data.bpop_wprice
                                    productToChange.bidMessage = highestBidderMsg
                                    setProduct(productToChange)
                                } else {
                                    // This user's autobid was placed
                                    //console.log('THIS USER AUTOBID WAS PLACED')
                                    productToChange.wprice = data.bpop_wprice
                                    productToChange.bidMessage = highestBidderMsg
                                    setProduct(productToChange)
                                }
                            }
                        }
                        // This user is outbid
                        else {
                            // LIVE AUCTION
                            if (data.auctiontype == 'live') {
                                //console.log('USER IS OUTBID')
                                productToChange.next_bid = data.bpop_nextbid_org
                                productToChange.wprice = data.bpop_wprice
                                productToChange.bidMessage = outbidMsg
                                setProduct(productToChange)
                            }
                            // BLIND AUCTION
                            else {
                                //console.log('out bid message___', outbidMsg)
                                productToChange.bidMessage = outbidMsg
                                productToChange.wprice = data.bpop_wprice
                                setProduct(productToChange)
                            }
                        }
                    } else {
                        // No bid for this user
                        if (data.auctiontype == 'live')
                            productToChange.next_bid = data.bpop_nextbid_org
                        productToChange.wprice = data.bpop_wprice
                        setProduct(productToChange)
                    }
                }
            }
        }
    } else if (type === 'bid_extended') {
        if (data.product_id == product.id) {
            productToChange.date_closed = data.date_extended
            console.log(data, '+++++++++')
            setProduct(productToChange)
        }
    } else if (type === 'drop_price') {
        if (data.id == product.id) {
            productToChange.sprice = data.sprice
            productToChange.wprice = data.sprice
            console.log(data, '+++++++++')
            setProduct(productToChange)
        }
    }
    // RESERVE PRICE CHANGE
    else {
        if (data.id == product.id) {
            if (data.reserveprice) {
                productToChange.rprice = parseInt(data.reserveprice)
                setProduct(productToChange)
            }
        }
    }
}

// bid socket handler for line item auction
export const lineItemBidSocketHandler = (
    data,
    type,
    user,
    lineItems,
    bulkBidLineItems,
    didBulkBid,
    setLineItems,
    setDwBidStatus,
    setBulkBidLineItems,
    setAlert,
    phrase,
) => {
    console.log('[LineItemBidSocketHandler] ', data)

    // BID EVENT
    if (type == 'bidAddtime') {
        // check if this product is on the page,
        let lineItemIndex = lineItems.findIndex((p) => {
            return p.id === parseInt(data.id)
        })
        console.log('LINE ITEM INDEX: ', lineItemIndex)
        // This line item is on the screen
        if (lineItemIndex !== -1) {
            let lineItemsCopy = [...lineItems]
            // This User just bid
            if (
                data.bpop_cbidder == user.id ||
                (user.companyid == data.bpop_companyid &&
                    data.bpop_companyid &&
                    data.bpop_companyid != '' &&
                    data.bpop_companyid != '0' &&
                    user.companyid &&
                    user.companyid != '' &&
                    user.companyid != '0')
            ) {
                console.log('MY BID')
                // bid success
                if (data.status != 'failed') {
                    if (didBulkBid) {
                        const bidIndex = bulkBidLineItems.findIndex((p) => {
                            return p.id === parseInt(data.id)
                        })
                        // bulk bid status messages
                        if (bidIndex !== -1) {
                            let bulkBidTemp = [...bulkBidLineItems]
                            bulkBidTemp[bidIndex].status = (
                                <td className="alert alert-success">{data.error}</td>
                            )
                            setBulkBidLineItems(bulkBidTemp)
                        }
                    }
                    // not the bulk bid
                    else {
                        setAlert('Bid placed successfully', 'success')
                    }

                    // update the company has bid variable
                    if (
                        data.bpop_companyid &&
                        data.bpop_companyid != '' &&
                        data.bpop_companyid != '0' &&
                        user.companyid &&
                        user.companyid != '' &&
                        user.companyid != '0'
                    ) {
                        lineItemsCopy[lineItemIndex].companyHasBid += 1
                    }
                    setDwBidStatus(true)
                    lineItemsCopy[lineItemIndex].incrementAmt = data.bpop_cuser_increment
                    lineItemsCopy[lineItemIndex].next_bid = data.bpop_cuser_nextbid
                    lineItemsCopy[lineItemIndex].bidtopstatus = data.bpop_bidstatus
                    lineItemsCopy[lineItemIndex].wprice = data.bpop_wprice
                    lineItemsCopy[lineItemIndex].bidPrice = data.cuser_enteramount
                    setLineItems(lineItemsCopy)
                }
                // bid fail
                else {
                    if (didBulkBid) {
                        const bidIndex = bulkBidLineItems.findIndex((p) => {
                            return p.id === parseInt(data.id)
                        })
                        // bulk bid status messages
                        if (bidIndex !== -1) {
                            let bulkBidTemp = [...bulkBidLineItems]
                            bulkBidTemp[bidIndex].status = (
                                <td className="alert alert-danger">{data.error}</td>
                            )
                            setBulkBidLineItems(bulkBidTemp)
                        }
                    } else {
                        setAlert(data.error, 'error')
                    }
                }
            }
            // Other user bid on the item
            else {
                console.log('NOT MY BID')
                // Live (normal) line item
                if (lineItemsCopy[lineItemIndex].bidtype != 'sealed') {
                    lineItemsCopy[lineItemIndex].incrementAmt = data.bpop_increment
                    lineItemsCopy[lineItemIndex].wprice = data.bpop_wprice

                    // Has not met reserve price
                    if (lineItemsCopy[lineItemIndex].rprice >= parseFloat(data.bpop_wprice)) {
                        lineItemsCopy[lineItemIndex].next_bid = data.bpop_nextbid_org
                    }

                    // if this user / company has previously bid on this line item
                    if (
                        parseInt(lineItemsCopy[lineItemIndex].hasBid) > 0 ||
                        parseInt(lineItemsCopy[lineItemIndex].companyHasBid) > 0
                    ) {
                        // if this user / company is still the highest
                        if (
                            data.bpop_higher == user.id ||
                            (user.companyid == data.bpop_highercompanyid &&
                                data.bpop_highercompanyid &&
                                data.bpop_highercompanyid != '' &&
                                data.bpop_highercompanyid != '0' &&
                                user.companyid &&
                                user.companyid != '' &&
                                user.companyid != '0')
                        ) {
                            // reserve price not met yet
                            if (
                                lineItemsCopy[lineItemIndex].rprice > parseFloat(data.bpop_wprice)
                            ) {
                                lineItemsCopy[
                                    lineItemIndex
                                ].bidtopstatus = `<div class='padboth' style="color: orange;"> ${phrase.highest_bidder} </div>`
                            } else {
                                lineItemsCopy[
                                    lineItemIndex
                                ].bidtopstatus = `<div class='padboth primeColor'> ${phrase.winning} </div>`
                            }
                            lineItemsCopy[lineItemIndex].bidPrice = data.bpop_wprice_morehigh
                        }
                        // outbid
                        else {
                            console.log('OUTBID')
                            lineItemsCopy[
                                lineItemIndex
                            ].bidtopstatus = `<div class='padboth' style="color: red;"> ${phrase.losing} </div>`
                        }
                    }
                }
                setLineItems(lineItemsCopy)
            }
        }
    }
    // AUCTION CLOSE
    else {
        let lineItemIndex = lineItems.findIndex((p) => {
            return p.id === parseInt(data.pid)
        })
        console.log('LINE ITEM INDEX: ', lineItemIndex)
        // this line item is on the page
        if (lineItemIndex !== -1) {
            let lineItemsCopy = [...lineItems]
            let wonMessage = `<div class="padboth primeColor"><strong>${phrase.won}</strong></div>`
            let lostMessage = `<div class="padboth" style="color: red;"><strong>${phrase.lost}</strong></div>`
            lineItemsCopy[lineItemIndex].isSocketUpdate = true
            // Awarded to someone
            if (data.usr != '') {
                lineItemsCopy[lineItemIndex].market_status = 'sold'
                // This User Won
                if (
                    data.usr == user.id ||
                    (user.companyid == data.cid &&
                        data.cid &&
                        data.cid != '' &&
                        data.cid != '0' &&
                        user.companyid &&
                        user.companyid != '' &&
                        user.companyid != '0')
                ) {
                    console.log('this user won')
                    lineItemsCopy[lineItemIndex].bidtopstatus = wonMessage
                }
                // This user lost or did not bid
                else {
                    // Check if this user lost, then show message
                    if (
                        parseInt(lineItemsCopy[lineItemIndex].hasBid) > 0 ||
                        parseInt(lineItemsCopy[lineItemIndex].companyHasBid)
                    ) {
                        console.log('this user lost')
                        lineItemsCopy[lineItemIndex].bidtopstatus = lostMessage
                    }
                }
                setLineItems(lineItemsCopy)
            }
            // No winner
            else {
                lineItemsCopy[lineItemIndex].market_status = 'closed'
                // this user bid, but no winner, then show lost messages
                if (
                    parseInt(lineItemsCopy[lineItemIndex].hasBid) > 0 ||
                    parseInt(lineItemsCopy[lineItemIndex].companyHasBid)
                ) {
                    console.log('no winner')
                    lineItemsCopy[lineItemIndex].bidtopstatus = lostMessage
                }
                setLineItems(lineItemsCopy)
            }
        }
    }
}
