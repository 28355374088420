import React from 'react'
import './App.css'
import Routes from './routes'
import { BrowserRouter as Router } from 'react-router-dom'
import { setAuthToken } from './common/api'
import { CookiesProvider } from 'react-cookie'
import AuthState from './context/auth/authState'
import CommonState from './context/common/commonState'
import UserState from './context/user/userState'
import AlertState from './context/alert/alertState'
import ProductState from './context/product/productState'
import AuctionState from './context/auction/auctionState'
import CartState from './context/cart/cartState'
import BuyerState from './context/buyer/buyerState'
import AutopltState from './context/autopilot/autopltState'
import PayState from './context/payment/payState'
import LoaderState from './context/loaders/loader.state'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { SnackbarProvider } from 'notistack'
import Alerts from './common/alert'

global.site_url = process.env.REACT_APP_DOMAIN
global.images_url = global.site_url + '/uploads/product/'
global.site_id = process.env.REACT_APP_SITE_ID ? process.env.REACT_APP_SITE_ID : 1
global.site_title = process.env.SITE_TITLE ? process.env.SITE_TITLE : 'LedMax'
global.maintainance_mode = false

if (localStorage.token) {
    setAuthToken(localStorage.token)
}

const App = () => {
    return (
        <CookiesProvider>
            <LoaderState>
                <CommonState>
                    <AuthState>
                        <AlertState>
                            <UserState>
                                <ProductState>
                                    <AuctionState>
                                        <CartState>
                                            <PayState>
                                                <BuyerState>
                                                    <AutopltState>
                                                        <MuiPickersUtilsProvider
                                                            utils={MomentUtils}
                                                        >
                                                            <SnackbarProvider maxSnack={1}>
                                                                <div className="App">
                                                                    <Alerts />
                                                                    <Router>
                                                                        <Routes />
                                                                    </Router>
                                                                </div>
                                                            </SnackbarProvider>
                                                        </MuiPickersUtilsProvider>
                                                    </AutopltState>
                                                </BuyerState>
                                            </PayState>
                                        </CartState>
                                    </AuctionState>
                                </ProductState>
                            </UserState>
                        </AlertState>
                    </AuthState>
                </CommonState>
            </LoaderState>
        </CookiesProvider>
    )
}

export default App
